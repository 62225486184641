const BASE = {
	BASE_URL: '/',
	APP_NAME: 'Rumis Form',
	APP_SHORT_NAME: 'RMK Form',
};

const PRODUCTION = {
	SERVER: 'https://back.rumis.cc',
	WSS: 'wss://back.rumis.cc',
};

const DEVELOPMENT = {
	SERVER: 'https://back.dev.forms.rumishq.com',
	WSS: 'wss://back.dev.forms.rumishq.com',
};

const LOCAL = {
	SERVER: 'https://back.dev.forms.rumishq.com',
	WSS: 'wss://back.dev.forms.rumishq.com',
};

//? --------------------- Config selection ----------------------------------------

export type CONFIG_TYPE = typeof BASE & typeof PRODUCTION & typeof DEVELOPMENT & typeof LOCAL;

const CONFIG_BY_MODE: CONFIG_TYPE = { ...(({ LOCAL, PRODUCTION, DEVELOPMENT } as any)[window.MODE as any] || {}) };

export const CONFIG = {
	...BASE,
	...CONFIG_BY_MODE,
	API_SERVER: CONFIG_BY_MODE?.SERVER + '/api',
};

window.CONFIG = CONFIG;
